/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.listRequestDate
{
    color:gray !important;
    font-size: 0.8em;
}
.nodata
{
    color:gray;
    font-size:1.4em;
    text-align: center;
    vertical-align: middle;
}

.description
{
    color:gray;
    font-size:0.8em;
}

.graytext
{
    color:gray;
}

.error-message
{
    color:rgb(189, 7, 7);
    font-size:0.8em;
    margin-left: 20px;
}

.textbox
{
    border:solid 1px silver; 
    border-radius: 5px;
}

.inputBox
{
    margin:5px;
    border:solid 1px #f2f4f7; 
    border-radius: 3px;
}

.greyBackground
{
    background-color: #f5f7f6;
}

.header
{
    display: flex;
    justify-content: space-between;
    margin: 0px 10px 0px 10px;
    align-items: center;
}

.header h2
{
    font-weight: bold;
}

.centerDiv
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.emptyList
{
    color:silver;
    font-size: 1.5em;
    flex-direction: column;
}

.whitebg
{
    background-color: #fff;
    border-radius: 10px;
}
  ion-header
{
    margin-top: env(safe-area-inset-top);
}